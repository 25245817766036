<template>

  <div class="flex flex-col">

    <div
      v-if="shipToLabel"
      class="text-sm uppercase p-2 pb-0 font-bold"
      :style="{ color: accentTextColor }"
      v-html="nl2brMX(shipToLabel)"
    ></div>

    <div
      v-if="shipToName"
      class="p-2 pb-0 text-lg pt-0"
      v-html="nl2brMX(shipToName)"
    ></div>

    <div
      v-if="shipToAddress"
      class="p-2 pt-0 text-gray-400"
      v-html="nl2brMX(shipToAddress)"
    ></div>

  </div>

</template>


<script>
  export default {
    props: [
      'accentTextColor',
      'shipToLabel',
      'shipToName',
      'shipToAddress'
    ]
  }
</script>