<template>

  <div
    v-if="notesLabel"
    class="w-full text-gray-400 uppercase text-xs font-bold p-1 py-0"
    v-html="nl2brMX(notesLabel)"
  ></div>

  <div
    v-if="notesText"
    class="w-full text-gray-600 p-1 py-0"
    v-html="nl2brMX(notesText)"
  ></div>

  <div
    v-if="termsLabel"
    class="mt-10 w-full text-gray-400 uppercase text-xs p-1 py-0 font-bold"
    v-html="nl2brMX(termsLabel)"
  ></div>

  <div
    v-if="termsText"
    class="w-full text-gray-600 p-1 py-0"
    v-html="nl2brMX(termsText)"
  ></div>

</template>


<script>
  export default {
    props: [
      'notesLabel',
      'notesText',
      'termsLabel',
      'termsText'
    ]
  }
</script>