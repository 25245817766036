<template>

  <div class="w-full flex">
    <div
      class="text-sm text-right text-gray-400 uppercase w-7/12 p-1"
    >
      {{ subtotalLabel }}
    </div>

    <div
      class="text-sm text-right w-5/12 p-1"
    >
      {{ currency.after_nr ? parseFloat(subtotal).toFixed(2) + ' ' + currency.symbol : currency.symbol + ' ' + parseFloat(subtotal).toFixed(2) }}
    </div>
  </div>

  <div
    v-if="parseFloat(discount) != 0"
    class="flex w-full mt-1"
  >
    <div
      class="text-sm text-right text-gray-400 uppercase w-7/12 p-1"
    >
      {{ discountLabel }}
    </div>
    <div
      class="text-sm text-right w-5/12 p-1"
    >
      <span
        v-if="discountType == '%'"
      >
        {{ currency.after_nr ? parseFloat(discountPercToCurr).toFixed(2) + ' ' + currency.symbol : currency.symbol + ' ' + parseFloat(discountPercToCurr).toFixed(2) }}
        <div class="text-gray-400">
          ({{ parseFloat(discount).toFixed(2) }}%)
        </div>
      </span>

      <span
        v-else
      >
        {{ currency.after_nr ? parseFloat(discount).toFixed(2) + ' ' + currency.symbol : currency.symbol + ' ' + parseFloat(discount).toFixed(2) }}
        <div class="text-gray-400">
          ({{ parseFloat(discountCurrToPerc).toFixed(2) }}%)
        </div>
      </span>
    </div>
  </div>


  <div
    v-if="parseFloat(tax) != 0"
    class="flex w-full mt-1"
  >
    <div
      class="text-sm text-right text-gray-400 uppercase w-7/12 p-1"
    >
      {{ taxLabel }}
    </div>
    <div
      class="text-sm text-right w-5/12 p-1"
    >
      <span
        v-if="taxType == '%'"
      >
        {{ currency.after_nr ? parseFloat(taxPercToCurr).toFixed(2) + ' ' + currency.symbol : currency.symbol + ' ' + parseFloat(taxPercToCurr).toFixed(2) }}
        <div class="text-gray-400">
          ({{ parseFloat(tax).toFixed(2) }}%)
        </div>
      </span>

      <span
        v-else
      >
        {{ currency.after_nr ? parseFloat(tax).toFixed(2) + ' ' + currency.symbol : currency.symbol + ' ' + parseFloat(tax).toFixed(2) }}
        <div class="text-gray-400">
          ({{ parseFloat(taxCurrToPerc).toFixed(2) }}%)
        </div>
      </span>
    </div>
  </div>

  <div
    v-if="parseFloat(shippingCost) != 0 && !isNaN(shippingCost)"
    class="w-full flex"
  >
    <div
      class="text-sm text-right text-gray-400 uppercase w-7/12 p-1"
    >
      {{ shippingCostLabel }}
    </div>

    <div
      class="text-sm text-right w-5/12 p-1"
    >
      {{ currency.after_nr ? parseFloat(shippingCost).toFixed(2) + ' ' + currency.symbol : currency.symbol + ' ' + parseFloat(shippingCost).toFixed(2) }}
    </div>
  </div>

  <div
    v-if="parseFloat(amountPaid) != 0"
    class="w-full flex"
  >
    <div
      class="font-bold text-right text-gray-400 uppercase w-7/12 p-1"
      :style="{ color: accentTextColor }"
    >
      {{ amountPaidLabel }}
    </div>

    <div
      class="font-bold text-right w-5/12 p-1"
      :style="{ color: accentTextColor }"
    >
      {{ currency.after_nr ? parseFloat(amountPaid).toFixed(2) + ' ' + currency.symbol : currency.symbol + ' ' + parseFloat(amountPaid).toFixed(2) }}
    </div>
  </div>

  <div class="w-full flex">
    <div
      class="font-bold text-right text-gray-400 uppercase w-7/12 p-1"
      :style="{ color: accentTextColor }"
    >
      {{ totalLabel }}
    </div>

    <div
      class="font-bold text-right w-5/12 p-1"
      :style="{ color: accentTextColor }"
    >
      {{ currency.after_nr ? parseFloat(total).toFixed(2) + ' ' + currency.symbol : currency.symbol + ' ' + parseFloat(total).toFixed(2) }}
    </div>
  </div>

</template>


<script>
  export default {
    props: [
      'accentTextColor',
      'currency',
      'accentColor',
      'subtotalLabel',
      'subtotal',
      'discountLabel',
      'discount',
      'discountType',
      'taxLabel',
      'tax',
      'taxType',
      'shippingCostLabel',
      'shippingCost',
      'amountPaidLabel',
      'amountPaid',
      'totalLabel',
      'total',
      'discountCurrToPerc',
      'discountPercToCurr',
      'taxCurrToPerc',
      'taxPercToCurr'
    ]
  }
</script>

<style>
  .input-symbol {
    padding: 5px 0px;
  }
</style>