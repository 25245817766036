<template>

  <div class="flex flex-col items-end">
    <div v-if="title" class="text-right text-4xl font-bold uppercase w-300px p-1">
      {{ title }}
    </div>

    <div v-if="serial" class="text-xl text-right text-gray-400 uppercase p-1">
      {{ serial }}
    </div>
  </div>

</template>


<script>
  export default {
    props: ['title','serial']
  }
</script>