<template>

  <div v-if="companyLogo">
    <img
      :src="companyLogo"
      class="p-2 max-w-200px max-h-150px overflow-hidden"
    />
  </div>

</template>

<script>
  export default {
    props: ['companyLogo']
  }
</script>