<template>

  <div class="flex flex-col">
    <div
      v-if="invoiceDateLabel"
      class="text-right text-gray-400 uppercase text-xs p-1 pb-0 mt-8"
    >
      {{ invoiceDateLabel }}
    </div>
    <div
      v-if="invoiceDate"
      class="text-right p-1 pt-0"
      :style="{ color: accentTextColor }"
    >
      {{ invoiceDate }}
    </div>

    <div
      v-if="paymentDateLabel"
      class="text-right text-gray-400 uppercase text-xs p-1 pb-0 mt-4"
    >
      {{ paymentDateLabel }}
    </div>
    <div
      v-if="paymentDate"
      class="text-right p-1 pt-0"
      :style="{ color: accentTextColor }"
    >
      {{ paymentDate }}
    </div>

    <div
      v-if="paymentTermsLabel"
      class="text-right text-gray-400 uppercase text-xs p-1 pb-0 mt-4"
    >
      {{ paymentTermsLabel }}
    </div>
    <div
      v-if="paymentTerms"
      class="text-right p-1 pt-0"
    >
      {{ paymentTerms }}
    </div>

    <div class="mt-10 flex justify-end">
      <div
        class="p-2 px-4 text-white uppercase rounded-l-lg"
        :style="{ backgroundColor: accentColor }"
      >
        {{ balanceDueLabel }}
      </div>

      <div
        class="p-2 pr-4 text-white text-right rounded-r-lg"
        :style="{ backgroundColor: accentColor }"
      >
        {{ formattedDueAmount }}
      </div>
    </div>

    <div class="my-6 text-xs text-right">
      <div
        class="uppercase"
        :style="{ color: accentTextColor }"
      >
        {{ currencyLabel }}
      </div>
      <div class="text-gray-400">
        {{ currency.currency }} ({{ currency.code }})
      </div>
    </div>

  </div>

</template>


<script>
  export default {
    props: [
      'accentColor',
      'accentTextColor',
      'invoiceDateLabel',
      'invoiceDate',
      'paymentDateLabel',
      'paymentDate',
      'paymentTermsLabel',
      'paymentTerms',
      'balanceDueLabel',
      'formattedDueAmount',
      'currency',
      'currencyLabel'
    ],
    components: {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }
</script>