<template>

    <div class="flex flex-col">
      <div class="flex">
        <div
          class="w-6/12 font-bold text-white p-2 rounded-tl-lg"
          :style="{ backgroundColor: accentColor }"
          v-html="nl2brMX(itemLabel)"
        ></div>

        <div
          class="w-2/12 font-bold text-white p-2"
          :style="{ backgroundColor: accentColor }"
          v-html="nl2brMX(quantityLabel)"
        ></div>

        <div
          class="w-2/12 font-bold text-white p-2"
          :style="{ backgroundColor: accentColor }"
          v-html="nl2brMX(rateLabel)"
        ></div>

        <div
          class="w-2/12 font-bold text-white p-2 rounded-tr-lg"
          :style="{ backgroundColor: accentColor }"
          v-html="nl2brMX(amountLabel)"
        ></div>
      </div>
    </div>

    <div
      style="break-inside: avoid;"
      v-for="(row,index) in itemRows"
      :key="index"
    >
      <div
        class="flex w-full relative border-l border-r border-b border-gray-300"
        :class="{ 'rounded-b-lg' : index == itemRows.length-1 }"

      >
        <div
          class="w-6/12 p-2 border-r border-gray-300"
          :class="{ 'rounded-bl-lg' : index == itemRows.length-1 }"
          v-html="nl2brMX(row.item)"
        ></div>

        <div
          class="w-2/12 p-2 border-r border-gray-300 flex items-center"
        >
          <span
            v-if="row.isTime"
            class="text-gray-400 pr-1"
          >
            <i class="fas fa-clock"></i>
          </span>
          {{ row.quantity }}
        </div>

        <div
          class="relative w-2/12 border-r border-gray-300 flex items-center"
        >
          <div v-if="!currency.after_nr" class="pl-2 pr-0">
            {{ currency.symbol }}
          </div>
          <div
            class="w-full p-2 pl-1"
          >
            {{ row.rate }}
          </div>

          <div
            class="absolute top-0 right-0 flex items-center p-2 px-0 h-full"
          >

            <div
              v-if="currency.after_nr || row.isTime"
              class="pr-2"
            >
              <span
                v-if="currency.after_nr"
              >
                {{ currency.symbol }}
              </span>

              <span
                v-if="row.isTime"
              >
                {{ row.perHourLabel }}
              </span>
            </div>

          </div>
        </div>

        <div
          class="w-2/12 flex items-center"
          :class="{ 'rounded-br-lg' : index == itemRows.length-1 }"
        >
          <div
            v-if="!currency.after_nr"
            class="pl-2 pr-0 w-2/12"
          >
            {{ currency.symbol }}
          </div>
          <div
            class="p-2 w-9/12"
            :class="[{'rounded-br-lg' : index == itemRows.length-1}, { 'pl-0' : !currency.after_nr } ]"
          >
            {{ row.amount }}
          </div>
          <div
            v-if="currency.after_nr"
            class="w-3/12 text-right pr-2"
          >
            {{ currency.symbol }}
          </div>
        </div>

      </div>
    </div>


</template>

<script>
  export default {
    props: [
      'accentColor',
      'currency',
      'rowValidation',
      'itemLabel',
      'quantityLabel',
      'rateLabel',
      'amountLabel',
      'itemRows'
    ]
  }
</script>