<template>

  <div
    v-if="name"
    class="mt-2 p-2 pb-0 text-lg"
    v-html="nl2brMX(name)"
  ></div>

  <div
    v-if="address"
    class="p-2 pt-0 text-gray-400"
    v-html="nl2brMX(address)"
  ></div>

</template>


<script>
  export default {
    props: [
      'name',
      'address'
    ]
  }
</script>